package digital.steva.dot.app

import kotlin.String

internal object BuildConfig {
  internal const val PROJECT_VERSION: String = "2024.08.10-S1538"

  internal const val DEFAULT_SERVER_URL: String = ""

  internal const val DEFAULT_USERNAME: String = ""

  internal const val DEFAULT_PASSWORD: String = ""
}
